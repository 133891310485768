import React from "react"
import { Link } from "gatsby"
import "@fontsource/newsreader"

export default function Home() {
  return (
    <div class="container">
      <div class="column">
        <h1 class="bio">bio</h1>
        <p class="text-normal">
          Interested in the way that science, technology, and society interact
          in theory and in practice, algorithmically or otherwise. Building
          ethical tools and studying prescriptions that constitute those
          outcomes. Studying digital ecosystems to identify, gauge, and
          reconcile epistemic inequality. My work is interdisciplinary and
          visits a variety of topics from urban planning and heat resiliency to
          social networks and their political economies.
        </p>

        <p class="text-normal">
          CV{" "}
          <a class="bio" href={"/mattcv.pdf"}>
            here
          </a>
          .
        </p>
        <p class="text-normal">Brooklyn Technical High School, 2021</p>
        <p class="text-normal">University of Michigan, 2025</p>
        <Link class="bio" to="/">
          back
        </Link>
      </div>
    </div>
  )
}
